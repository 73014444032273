import React from 'react'
import bgVideo from "../../assets/videos/video-bg2.mp4";
import ScrollReveal from '../framerMotion/scrollToReveal';
import { fadeUpVariants } from '../framerMotion/motionVariants';
import { Link } from 'react-router-dom';
import { IoIosArrowRoundForward } from 'react-icons/io';

interface theProps {
    contactUsRef?: any
}


const NewHeroSection = ({ contactUsRef }: theProps) => {

    //handleScroll
    const handleScroll = (ref: any) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <div className="2xl:h-[800px] xl:h-[700px] lg:h-[700px] md:h-[700px] h-[700px] w-full">
                <div className="w-full relative bg-gradient-to-b from-yellow-500 to-defaultYellow">
                    <video
                        className="2xl:h-[800px] xl:h-[700px] lg:h-[700px] md:h-[700px] h-[700px] w-full object-cover"
                        src={bgVideo}
                        autoPlay
                        loop
                        muted
                        controls={false}
                    />
                    <div className="2xl:h-[800px] xl:h-[700px] lg:h-[700px] md:h-[700px] h-[700px] w-full absolute bg-blue-950 bottom-0 bg-opacity-[50%]">
                        <div className="w-full flex justify-center items-center  h-full 2xl:px-[300px] xl:px-[150px] lg:px-[150px] md:px-[40px] px-[20px]">
                            <ScrollReveal variants={fadeUpVariants}>
                                <div className="px-5 pb-5 w-full flex flex-col items-center">
                                    <p className="2xl:w-[650px] xl:w-[650px] lg:w-[550px] md:w-[450px] mt-4 2xl:text-[62px] xl:text-[62px] lg:text-[62px] md:text-[42px] text-[32px] font-bold text-[#e7e601] text-center" >
                                        Elevating Ideas into Digital Mastery
                                    </p>

                                    <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[16px] text-[14px] mt-10 text-white 2xl:w-[700px] xl:w-[700px] lg:w-[650px] md:w-[500px] text-center">
                                        Welcome to Gaspei, where we transform visionary ideas into digital mastery. Specializing in web development, mobile app creation, custom software solutions, and expert IT consultancy, we empower businesses to thrive in today's digital landscape.
                                    </p>

                                    <div className="mt-10">
                                        <Link to="#" onClick={() => handleScroll(contactUsRef)} className='flex gap-1 justify-center items-center h-[60px] w-[300px] px-5 rounded-[30px] bg-gradient-to-r from-yellow-500 to-defaultYellow text-white'>
                                            <p className='2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[14px] text-[14px]'>Get Started</p>
                                            <IoIosArrowRoundForward className='h-5 w-5' />
                                        </Link>
                                    </div>
                                </div>
                            </ScrollReveal>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewHeroSection
