import './App.css';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Homepage from './pages/home/homepage';
import AnimatedCursor from "react-animated-cursor"
import MuuveExpressPage from './components/ourClients/pages/muuveExpressPage';
import RydsomPage from './components/ourClients/pages/rydsomPage';
import GaspeiInvoicePage from './components/ourClients/pages/gaspeiInvoicePage';
// import { FloatButton } from 'antd';
// import { IoLogoWhatsapp } from "react-icons/io5";


function App() {
  return (
    <>
      <AnimatedCursor innerSize={15} outerSize={30} />

      <Router>
        <Routes>
          <Route path='/' element={<Homepage />} />
          {/* <Route path='/clients/muuv-express' element={<MuuveExpressPage />} />
          <Route path='/clients/rydsom' element={<RydsomPage />} />
          <Route path='/clients/gaspei-invoice' element={<GaspeiInvoicePage />} /> */}
        </Routes>
      </Router>


      {/* <FloatButton
        className='h-20 w-20 shadow-md'
        type='default'
        icon={<IoLogoWhatsapp />}
        style={{ background: '#25D366' }}
        href='https://wa.me/+233247360007' target='_blank'
      /> */}
    </>
  );
}

export default App;
