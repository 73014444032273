import React from 'react'
import ScrollReveal from '../framerMotion/scrollToReveal';
import { slideInLeftVariants, slideInRightVariants } from '../framerMotion/motionVariants';


const Oursolutions = () => {
    return (
        <>
            <div className="w-full 2xl:grid xl:grid lg:grid md:grid grid-cols-2 gap-10">
                <div className="w-full 2xl:pt-[100px] xl:pt-[100px] lg:pt-[70px] md:pt-[50px]">
                    <ScrollReveal variants={slideInLeftVariants}>
                        <div className="w-full bg-white shadow-md rounded-[10px] p-[10px] hover:shadow-lg mb-5">
                            <div className="w-full bg-gray-50 rounded-[5px] h-[300px]">
                                <img className='h-full w-full rounded-[5px] object-cover' src="https://img.freepik.com/premium-vector/3d-handhold-phone-mobile-app-development-application-building-app-testing-ui-ux-web-design-abstract-3d-object-background-3d-vector-illustration_145666-1589.jpg" alt="" />
                            </div>

                            <div className="w-full p-5 text-black text-opacity-[80%]">
                                <p className='text-[24px] font-[600] mt-5'>Mobile Application Development</p>
                                <p className='text-[18px] font-[400] text-gray-500 mt-5'>
                                    Stay ahead in the mobile-first world with our expert mobile app development services. From iOS to Android, we build intuitive apps that engage users and elevate your brand's presence on every device.
                                </p>
                            </div>
                        </div>
                    </ScrollReveal>

                    <ScrollReveal variants={slideInRightVariants}>
                        <div className="w-full bg-white shadow-md rounded-[5px] p-[10px] hover:shadow-lg mt-[40px] mb-5">
                            <div className="w-full bg-gray-50 rounded-[5px] h-[300px]">
                                <img className='h-full w-full rounded-[5px] object-cover' src="https://cdn3d.iconscout.com/3d/premium/thumb/programming-6319119-5203659.png" alt="" />
                            </div>

                            <div className="w-full p-5 text-black text-opacity-[80%]">
                                <p className='text-[24px] font-[600] mt-5'>Custom Software & API Development</p>
                                <p className='text-[18px] font-[400] text-gray-500 mt-5'>
                                    Leverage our expertise in custom software solutions and API development to streamline operations and drive efficiency. From designing scalable software applications to creating robust APIs that integrate seamlessly with your systems, we empower your business with tailored digital solutions
                                </p>
                            </div>
                        </div>
                    </ScrollReveal>
                </div>

                <div className="w-full 2xl:pb-[100px] xl:pb-[100px] lg:pb-[70px] md:pb-[50px]">
                    <ScrollReveal variants={slideInRightVariants}>
                        <div className="w-full bg-white shadow-md rounded-[5px] p-[10px] hover:shadow-lg 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 mt-[40px] mb-5">
                            <div className="w-full bg-gray-50 rounded-[5px] h-[300px]">
                                <img className='h-full w-full rounded-[5px] object-cover' src="https://media.licdn.com/dms/image/D5612AQGlhqko4c-sOg/article-cover_image-shrink_720_1280/0/1692364956037?e=2147483647&v=beta&t=wt4N1njDDoYZmJPioDsGJnEjRSKmpQRirlMJ3bAyc9Y" alt="" />
                            </div>

                            <div className="w-full p-5 text-black text-opacity-[80%]">
                                <p className='text-[24px] font-[600] mt-5'>Web Application Development</p>
                                <p className='text-[18px] font-[400] text-gray-500 mt-5'>
                                    At Gaspei, we craft bespoke web solutions tailored to meet your business objectives. Whether you need a dynamic e-commerce platform or a sleek corporate website, our team ensures seamless functionality and stunning design.
                                </p>
                            </div>
                        </div>
                    </ScrollReveal>

                    <ScrollReveal variants={slideInLeftVariants}>
                        <div className="w-full bg-white shadow-md rounded-[5px] p-[10px] hover:shadow-lg mt-[40px] mb-5">
                            <div className="w-full bg-gray-50 rounded-[5px] h-[300px]">
                                <img className='h-full w-full rounded-[5px] object-cover' src="https://cdn3d.iconscout.com/3d/premium/thumb/business-trainer-discussing-business-analysis-chart-8688348-6995842.png" alt="" />
                            </div>

                            <div className="w-full p-5 text-black text-opacity-[80%]">
                                <p className='text-[24px] font-[600] mt-5'>IT Consultancy Services</p>
                                <p className='text-[18px] font-[400] text-gray-500 mt-5'>
                                    Partner with Gaspei for strategic IT consultancy tailored to your business goals. Whether you're optimizing processes, implementing new technologies, or enhancing cybersecurity, our consultants provide expert guidance every step of the way.
                                </p>
                            </div>
                        </div>
                    </ScrollReveal>
                </div>
            </div>
        </>
    )
}

export default Oursolutions
