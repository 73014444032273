import React, { useState } from 'react';
import { Form, Input, notification, Spin } from 'antd';
import { IoIosArrowRoundForward } from 'react-icons/io';
import emailjs from 'emailjs-com';


const ContactForm = () => {

    const [form] = Form.useForm();
    const [isBusy, setIsBusy] = useState(false)

    const onFinish = (values: any) => {
        const templateParams = {
            fullName: values.fullName,
            email: values.email,
            phone: values.phone,
            message: values.message,
        };

        setIsBusy(true)

        emailjs.send('service_cqj9759', 'template_a7242mc', templateParams, 'X16elOZ7659c3pxQY')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                notification.success({
                    message: 'Email Sent',
                    description: 'Your message has been sent successfully.',
                });
                form.resetFields()
                setIsBusy(false)
            }, (error) => {
                console.log('FAILED...', error);
                notification.error({
                    message: 'Email Sending Failed',
                    description: 'There was an error sending your message. Please try again later.',
                });
                setIsBusy(false)
            });
    };


    return (
        <div className="w-full bg-white shadow-md rounded-[10px] p-10 text-black text-opacity-[80%]">
            <p className='text-[18px] font-[600]'>Let's get you started ASAP</p>

            <Spin spinning={isBusy}>
                <Form form={form}
                    name="contact"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    layout="vertical"
                    className='mt-5'
                >
                    <Form.Item
                        label="Full Name"
                        name="fullName"
                        rules={[{ required: true, message: 'Please input your full name!' }]}
                    >
                        <Input className='h-[40px] w-full' placeholder='John Doe' />
                    </Form.Item>

                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            { required: true, message: 'Please input your email!' },
                            { type: 'email', message: 'The input is not valid E-mail!' }
                        ]}
                    >
                        <Input className='h-[40px] w-full' placeholder='example@gmail.com' />
                    </Form.Item>

                    <Form.Item
                        label="Phone"
                        name="phone"
                        rules={[{ required: true, message: 'Please input your phone number!' }]}
                    >
                        <Input className='h-[40px] w-full' placeholder='0000000000' />
                    </Form.Item>

                    <Form.Item
                        label="Message"
                        name="message"
                        rules={[{ required: true, message: 'Please input your message!' }]}
                    >
                        <Input.TextArea className='w-full' style={{ height: 60 }} placeholder='Type here' />
                    </Form.Item>

                    <Form.Item className='flex justify-end'>
                        <button type="submit" className='flex gap-1 items-center h-[40px] px-5 rounded-[30px] bg-gradient-to-r from-yellow-500 to-defaultYellow text-white'>
                            <p className='2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[14px] text-[14px]'>Submit</p>
                            <IoIosArrowRoundForward className='h-5 w-5' />
                        </button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    );
};

export default ContactForm;
