import React from 'react'
import { HiOutlineBriefcase, HiOutlineClock } from "react-icons/hi2";
import { IoThumbsUpOutline } from "react-icons/io5";
import { CountUp } from 'use-count-up'


const TheNumbers = () => {
    return (
        <>
            <div className="w-full 2xl:grid xl:grid lg:grid md:grid grid-cols-3 gap-10 items-center">
                {/* 1 */}
                <div className="w-full flex gap-5 items-center justify-center mb-8 2xl:mb-0 xl:mb-0 lg:mb-0 md:mb-0">
                    <div className='h-20 w-20 flex items-center justify-center rounded-full bg-gradient-to-b from-yellow-500 to-defaultYellow text-white'>
                        <HiOutlineBriefcase className='h-10 w-10' />
                    </div>

                    <div className='text-black text-opacity-[80%]'>
                        <p className='text-[32px] font-[600]'>
                            <CountUp isCounting end={12} duration={3.2} />+
                        </p>
                        <p>Projects Completed</p>
                    </div>
                </div>

                {/* 2 */}
                <div className="w-full flex gap-5 items-center justify-center mb-8 2xl:mb-0 xl:mb-0 lg:mb-0 md:mb-0">
                    <div className='h-20 w-20 flex items-center justify-center rounded-full bg-gradient-to-b from-yellow-500 to-defaultYellow text-white'>
                        <IoThumbsUpOutline className='h-10 w-10' />
                    </div>

                    <div className='text-black text-opacity-[80%]'>
                        <p className='text-[32px] font-[600]'>
                            <CountUp isCounting end={12} duration={3.2} />+
                        </p>
                        <p>Happy Clients</p>
                    </div>
                </div>

                {/* 3 */}
                <div className="w-full flex gap-5 items-center justify-center">
                    <div className='h-20 w-20 flex items-center justify-center rounded-full bg-gradient-to-b from-yellow-500 to-defaultYellow text-white'>
                        <HiOutlineClock className='h-10 w-10' />
                    </div>

                    <div className='text-black text-opacity-[80%]'>
                        <p className='text-[32px] font-[600]'>24/7</p>
                        <p>Availability</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TheNumbers
