import React from 'react'

interface theProps {
    title: string
}

const CustomTag = ({ title }: theProps) => {
    return (
        <>
            <div className="flex gap-2 items-center">
                <div className="h-1 w-20 bg-gradient-to-tr from-yellow-400 to-defaultYellow rounded"></div>
                <p className='text-[18px] font-[500] text-defaultYellow'>{title}</p>
            </div>
        </>
    )
}

export default CustomTag
