import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import logoImg from "../assets/images/logo-white.png"
import { IoIosArrowRoundForward } from 'react-icons/io'


interface navProps {
    homeRef?: any
    solutionsRef?: any
    aboutRef?: any
    clientsRef?: any
    contactUsRef?: any
}



const CustomNavbar = ({ homeRef, solutionsRef, aboutRef, clientsRef, contactUsRef }: navProps) => {

    const [isScrolled, setIsScrolled] = useState(false)

    const changeBackground = () => {
        if (window.scrollY >= 100) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        console.log('Component mounted, adding scroll event listener');
        window.addEventListener('scroll', changeBackground);
        return () => {
            console.log('Component unmounted, removing scroll event listener');
            window.removeEventListener('scroll', changeBackground);
        };
    }, []);

    //handleScroll
    const handleScroll = (ref: any) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <nav className={`${isScrolled ? 'navbar active' : 'navbar'} fixed w-full z-50`}>
                <div className="w-full py-5 flex justify-between items-center top-0 z-50 2xl:px-[300px] xl:px-[150px] lg:px-[150px] md:px-[40px] px-[20px] bg-transparent">
                    {/* left */}
                    <div>
                        <Link to="#" onClick={() => handleScroll(homeRef)}>
                            <img className='w-[100px]' src={logoImg} alt="logo" />
                        </Link>
                    </div>

                    {/* middle */}
                    <div className="2xl:flex xl:flex lg:flex md:flex hidden gap-10 items-center">
                        <Link to="#" onClick={() => handleScroll(solutionsRef)} className='text-[16px] font-[400] text-white border-b-[2px] border-b-transparent hover:border-b-white'>
                            Our Solutions
                        </Link>
                        <Link to="#" onClick={() => handleScroll(aboutRef)} className='text-[16px] font-[400] text-white border-b-[2px] border-b-transparent hover:border-b-white'>
                            About Us
                        </Link>
                        <Link to="#" onClick={() => handleScroll(clientsRef)} className='text-[16px] font-[400] text-white border-b-[2px] border-b-transparent hover:border-b-white'>
                            Our Clients
                        </Link>
                    </div>

                    {/* right */}
                    <div>
                        <Link to="#" onClick={() => handleScroll(contactUsRef)} className='flex gap-1 items-center h-[40px] px-5 rounded-[30px] bg-gradient-to-r from-yellow-500 to-defaultYellow text-white'>
                            <p className='text-[14px]'>Get Started</p>
                            <IoIosArrowRoundForward className='h-5 w-5' />
                        </Link>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default CustomNavbar
