import React from 'react';
import { motion } from 'framer-motion';

interface motionProps {
    children: any
    variants: any
}


const ScrollReveal = ({ children, variants }: motionProps) => {

    return (
        <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.2 }}
            transition={{ duration: 0.6 }}
            variants={variants}
            style={{ overflow: 'hidden' }}
        >
            {children}
        </motion.div>
    );
};

export default ScrollReveal;
