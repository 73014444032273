import React from 'react';
import logoImg from "../../assets/images/logo-white.png"
import { Link } from 'react-router-dom';
import ScrollReveal from '../framerMotion/scrollToReveal';
import { zoomOutVariants } from '../framerMotion/motionVariants';


interface navProps {
  solutionsRef?: any
  aboutRef?: any
  clientsRef?: any
  contactUsRef?: any
}

const Footer = ({ solutionsRef, aboutRef, clientsRef, contactUsRef }: navProps) => {

  //handleScroll
  const handleScroll = (ref: any) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <ScrollReveal variants={zoomOutVariants}>
        <div className="grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 grid-cols-2 text-white gap-10">
          <div className="">
            <img className='w-[100px]' src={logoImg} alt="logo" />
          </div>

          {/* company */}
          <div>
            <p className="font-medium">Company</p>

            <ul className="mt-6 space-y-4 text-sm">
              <li>
                <Link to="#" className="transition hover:opacity-75" onClick={() => handleScroll(solutionsRef)}> Our Solutions </Link>
              </li>

              <li>
                <Link to="#" className="transition hover:opacity-75" onClick={() => handleScroll(aboutRef)}> About Us </Link>
              </li>

              <li>
                <Link to="#" className="transition hover:opacity-75" onClick={() => handleScroll(clientsRef)}> Our Clients </Link>
              </li>

              <li>
                <Link to="#" className="transition hover:opacity-75" onClick={() => handleScroll(contactUsRef)}> Contact Us </Link>
              </li>
            </ul>
          </div>

          {/* call us */}
          <div>
            {/* <p>
              <span className="text-xs uppercase tracking-wide"> Call us </span>

              <Link to="tel:+233245857866" className="block text-xl font-medium hover:opacity-75">
                +233 24 585 7866
              </Link>
            </p> */}

            <p>
              <span className="text-xs uppercase tracking-wide"> Email </span>
              <Link to="mailto:support@gaspei.com" className="block text-xl font-medium hover:opacity-75">
                support@gaspei.com
              </Link>
            </p>

            <ul className="mt-8 space-y-1 text-sm ">
              <li>Monday to Friday: 9am - 5pm</li>
              <li>Weekends: 10am - 3pm</li>
            </ul>
          </div>
        </div>

        {/* terms and conditions */}
        <div className="mt-10 w-full border-t border-gray-100 pt-5">
          <div className="flex items-center justify-between">
            <ul className="flex flex-wrap gap-4 text-sm">
              <li>
                <Link to="#" className="text-gray-300 transition hover:opacity-75"> Terms & Conditions </Link>
              </li>

              <li>
                <Link to="#" className="text-gray-300 transition hover:opacity-75"> Privacy Policy </Link>
              </li>
            </ul>

            <div className="mt-8">
              <p className="text-sm text-gray-300">
                &copy; 2024. Gaspei. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </ScrollReveal>
    </>
  );
}

export default Footer;
