import React, { useRef } from 'react'
import NewHeroSection from '../../components/hero/newHeroSection'
import CustomTag from '../../components/customTag'
import Oursolutions from '../../components/solutions/oursolutions'
import CustomNavbar from '../../components/customNavbar'
import TheNumbers from '../../components/aboutus/theNumbers'
import Footer from '../../components/footer/Footer'
import OurClients from '../../components/ourClients/ourClients'
import ContactForm from '../../components/contact/contactForm'
import ScrollReveal from '../../components/framerMotion/scrollToReveal'
import { fadeInVariants, fadeUpVariants, slideInLeftVariants, slideInRightVariants } from '../../components/framerMotion/motionVariants'


const Homepage = () => {

    const homeRef = useRef(null);
    const solutionsRef = useRef(null);
    const aboutUsRef = useRef(null);
    const clientsRef = useRef(null);
    const contactUsRef = useRef(null);

    return (
        <>
            <div ref={homeRef} className='h-screen w-full'>

                <CustomNavbar homeRef={homeRef} solutionsRef={solutionsRef} aboutRef={aboutUsRef} clientsRef={clientsRef} contactUsRef={contactUsRef} />

                <NewHeroSection contactUsRef={contactUsRef} />

                {/* solutions */}
                <div ref={solutionsRef} className="w-full 2xl:px-[300px] xl:px-[150px] lg:px-[150px] md:px-[40px] px-[20px] py-[100px] bg-gray-50">
                    <ScrollReveal variants={fadeInVariants}>
                        <CustomTag title='Our Amazing Solutions' />
                        <p className='text-[32px] font-[600] mt-3 text-black text-opacity-[80%] 2xl:w-[650px] xl:w-[650px] lg:w-[550px] md:w-[450px]'>
                            Propel Your Business with Innovative Solutions
                        </p>

                        <p className='text-[18px] font-[400] mt-3 text-gray-500 2xl:w-[750px] xl:w-[750px] lg:w-[650px] md:w-[550px]'>
                            Discover how Gaspei can accelerate your business with our diverse suite of digital services. From creating dynamic web platforms and engaging mobile apps to developing customized software solutions and seamless APIs, we specialize in empowering startups and enterprises alike. Our dedicated team blends creativity and technical prowess to deliver solutions that propel your business forward in today's fast-paced digital world. Partner with Gaspei and unlock new possibilities for growth and success.
                        </p>
                    </ScrollReveal>

                    <div className="mt-20 w-full relative">
                        <Oursolutions />
                    </div>
                </div>


                {/* about us */}
                <div ref={aboutUsRef} className="w-full 2xl:px-[300px] xl:px-[150px] lg:px-[150px] md:px-[40px] px-[20px] py-[100px] bg-blue-950 bg-opacity-[5%]">
                    <ScrollReveal variants={fadeUpVariants}>
                        <div className="flex flex-col items-center">
                            <CustomTag title='Who are we?' />
                            <p className='text-[32px] font-[600] mt-3 text-black text-opacity-[80%] 2xl:w-[650px] xl:w-[650px] lg:w-[550px] md:w-[450px] text-center'>
                                The backbone for your business
                            </p>

                            <p className='text-[18px] font-[400] mt-5 text-gray-500 w-full text-center'>
                                At Gaspei, we specialize in providing cutting-edge digital solutions that empower businesses to thrive in the modern digital landscape. With a deep understanding of technology and a commitment to excellence, we partner with startups, small businesses, and enterprises to deliver tailored web development, mobile app development, and custom software solutions. Our dedicated team of experts is passionate about creating impactful digital experiences that drive growth and efficiency. Whether you're looking to enhance your online presence, optimize business operations, or innovate through technology, Gaspei is your trusted partner for sustainable success.
                            </p>
                        </div>
                    </ScrollReveal>

                    <div className="mt-20 w-full">
                        <TheNumbers />
                    </div>
                </div>


                {/* our clients */}
                {/* <div ref={clientsRef} className="w-full 2xl:px-[300px] xl:px-[150px] lg:px-[150px] md:px-[40px] px-[20px] py-[100px] bg-gray-50">
                    <ScrollReveal variants={fadeInVariants}>
                        <CustomTag title='Our Clients' />
                        <p className='text-[32px] font-[600] mt-3 text-black text-opacity-[80%] 2xl:w-[650px] xl:w-[650px] lg:w-[550px] md:w-[450px]'>
                            Our Trusted Partners
                        </p>

                        <p className='text-[18px] font-[400] mt-3 text-gray-500 2xl:w-[750px] xl:w-[750px] lg:w-[650px] md:w-[550px]'>
                            Gaspei is proud to have collaborated with a diverse range of clients across various industries. From startups and small businesses to established enterprises, our commitment to delivering high-quality digital solutions has earned us the trust of our clients. Some of our valued partners include leading brands in e-commerce, healthcare, finance, and more. We are dedicated to building long-term relationships based on mutual success and innovation.
                        </p>
                    </ScrollReveal>

                    <div className="mt-20 w-full">
                        <OurClients />
                    </div>
                </div> */}

                {/* contact */}
                <div ref={contactUsRef} className="w-full 2xl:px-[300px] xl:px-[150px] lg:px-[150px] md:px-[40px] px-[20px] py-[100px] bg-blue-950 bg-opacity-[5%]">
                    <div className="2xl:grid xl:grid lg:grid md:grid grid-cols-2 w-full items-center gap-20">
                        <ScrollReveal variants={slideInLeftVariants}>
                            <div className="w-full mb-10 2xl:mb-0 xl:mb-0 lg:mb-0 md:mb-0">
                                <CustomTag title='Contact Us' />
                                <p className='text-[32px] font-[600] mt-3 text-black text-opacity-[80%]'>
                                    Just send us a message
                                </p>

                                <p className='text-[18px] font-[400] mt-3 text-gray-500'>
                                    We'd love to hear from you! Whether you have a question about our services, want to collaborate, or just say hello, feel free to reach out using the form provided. We'll get back to you as soon as possible. Your feedback matters to us!
                                </p>

                                <p className='text-[18px] font-[400] mt-5 text-gray-500'>
                                    At Gaspei, customer satisfaction is our top priority. We strive to provide exceptional service and support to ensure your experience with us is nothing short of excellence. Don't hesitate to get in touch - we're here to help!
                                </p>
                            </div>
                        </ScrollReveal>

                        <ScrollReveal variants={slideInRightVariants}>
                            <div className="w-full">
                                <ContactForm />
                            </div>
                        </ScrollReveal>
                    </div>
                </div>

                <div className="2xl:px-[300px] xl:px-[150px] lg:px-[150px] md:px-[40px] px-[20px] bg-blue-950 py-20 text-white bg-footerBg bg-no-repeat">
                    <Footer solutionsRef={solutionsRef} aboutRef={aboutUsRef} clientsRef={clientsRef} contactUsRef={contactUsRef} />
                </div>

            </div>
        </>
    )
}

export default Homepage
